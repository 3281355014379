<template>
    <div>
        <a @click.prevent="$root.$children[0].openModal('kva-modal', {
                            id:row.item.id,
                            formType:type || null
                        })"
           href="#">{{ row.item.number }}</a>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'type': [String],
    },
}
</script>